
















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import Branding from '@/components/Branding.vue';
import { Events } from '@/events';

@Component({
    components: {
        Branding,
    },
})
export default class Launcher extends Vue {
    private state = getModule(GlobalState, this.$store);

    public mounted(): void {
        // Load models when we have support for WebXR
        if (this.state.xrSupported || this.state.devMode) {
            this.loadModels();
        }
    }

    private async loadModels(): Promise<void> {
        try {
            this.state.loadModels();
        } catch (error: any) {
            let errorMsg = error.toString();
            if (error instanceof Response) {
                errorMsg = `Status ${error.status} ${error.statusText}`;
            }
            alert(`Failed to load models: ${errorMsg}`);
            console.error(error);
        }
    }

    public launchXR(): void {
        // Check WebXR availability again
        const xrAvailable = (navigator as any).xr !== undefined;
        if (!xrAvailable) {
            alert('WebXR not supported. 😢');
            return;
        }

        // Everything is triggered via an event
        this.$root.$emit(Events.LaunchXR);
    }
}
