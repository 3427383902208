














import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import TopBar from '@/components/TopBar.vue';
import ModelsDeck from '@/components/ModelsDeck.vue';
import Toolbar from '@/components/Toolbar.vue';
import Onboarding from '@/components/Onboarding.vue';
import CartView from '@/views/CartView.vue';
import DemoHint from '@/components/DemoHint.vue';
import { Events } from '@/events';
import { Model } from '@bitforgehq/yago-api-client';

@Component({
    components: {
        TopBar,
        ModelsDeck,
        Toolbar,
        CartView,
        DemoHint,
        Onboarding,
    },
})
export default class DomOverlay extends Vue {
    private state = getModule(GlobalState, this.$store);
    public selectedModel: Model | null = null;

    public mounted(): void {
        this.$root.$on(Events.SelectModel, this.onModelSelected);
    }

    public beforeDestroy(): void {
        this.$root.$off(Events.SelectModel, this.onModelSelected);
    }

    public onModelSelected(modelId: string): void {
        const model = this.state.getModelById(modelId);
        if (model) this.selectedModel = model;
    }

    public get showTopBar(): boolean {
        return this.state.xrActive;
    }

    public get showDeck(): boolean {
        return (this.state.xrSupported && !this.state.xrActive) || (this.state.xrActive && this.state.xrTracking);
    }

    public get showToolbar(): boolean {
        return this.state.xrActive || this.state.devMode;
    }

    public get showOnboarding(): boolean {
        return this.state.xrActive;
    }

    public get showCart(): boolean {
        return this.state.showCart;
    }

    public get showDemoHint(): boolean {
        return this.state.showDemoHint;
    }

    public get showStats(): boolean {
        return this.state.xrActive && this.state.devMode;
    }
}
