






































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import ToolbarButton from '@/components/ToolbarButton.vue';
import { Events } from '@/events';

@Component({
    components: {
        ToolbarButton,
    },
})
export default class Toolbar extends Vue {
    private state = getModule(GlobalState, this.$store);

    /** Remove last placed model */
    public unplaceModel(): void {
        this.$root.$emit(Events.UnplaceModel);
    }

    /** Remove focused model */
    public deleteModel(): void {
        this.$root.$emit(Events.RemoveModel, this.state.focused);
    }

    /** Hides most UI elements to view placed models only */
    public enableViewOnlyMode(): void {
        this.state.setViewOnlyMode(true);
    }

    /** Show all UI elements again */
    public disableViewOnlyMode(): void {
        this.state.setViewOnlyMode(false);
    }

    /** Show current place models cart */
    public showCart(): void {
        this.state.setShowCart(true);
    }
}
