









import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';

@Component
export default class DemoHint extends Vue {
    private state = getModule(GlobalState, this.$store);

    public onClose(): void {
        this.state.setShowDemoHint(false);
    }
}
