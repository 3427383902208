









import { Component, Vue, Prop } from 'vue-property-decorator';
import LineItem from '@/cart/LineItem';

@Component
export default class ModelCard extends Vue {
    @Prop()
    public item!: LineItem;
}
