



















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import QRCodeStyling, { CornerDotType, CornerSquareType, DotType, DrawType } from 'qr-code-styling';
import Branding from '@/components/Branding.vue';

@Component({
    components: {
        Branding,
    },
})
export default class FallbackContent extends Vue {
    private state = getModule(GlobalState, this.$store);

    // QR Code styling options
    private qrUrl = 'https://deck.yago.cloud';
    private qrCode: QRCodeStyling | null = null;
    private qrOptions = {
        width: 250,
        height: 250,
        type: 'svg' as DrawType,
        data: this.qrUrl,
        dotsOptions: {
            type: 'rounded' as DotType,
        },
        cornersSquareOptions: {
            type: 'dot' as CornerSquareType,
        },
        cornersDotOptions: {
            type: 'dot' as CornerDotType,
        },
    };

    public mounted(): void {
        // Render QR code when WebXR is not supported
        if (!this.state.xrSupported) this.renderQRCode();
    }

    private renderQRCode(): void {
        const qrElement = document.querySelector('.qr-element') as HTMLElement;
        if (!qrElement) return;

        this.qrUrl = window.location.toString();
        this.qrCode = new QRCodeStyling(this.qrOptions);
        this.qrCode.append(qrElement);
        this.qrCode?.update(this.qrOptions);
    }
}
