














import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import { Events } from '@/events';
import { Model } from '@bitforgehq/yago-api-client';

@Component
export default class TopBar extends Vue {
    public state = getModule(GlobalState, this.$store);

    public get isModelFocused(): boolean {
        return this.state.focused != null;
    }

    public get showBackButton(): boolean {
        return this.state.xrActive;
    }

    public get title(): string {
        return this.state.focused?.userData?.name || '';
    }

    public endSession(): void {
        this.$root.$emit(Events.EndXR);
    }

    public navigateToModelUrl(): void {
        if (!this.state.focused) return;
        const model = this.state.focused.userData as Model;
        if (model.siteUrl) {
            window.open(model.siteUrl, '_blank')?.focus();
            this.state.setFocused(null);
            this.$root.$emit(Events.EndXR);
        }
    }
}
