








import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';

@Component
export default class ToolbarButton extends Vue {
    private state = getModule(GlobalState, this.$store);

    @Prop({ default: 'home' })
    public icon!: string;

    @Prop({ default: '?' })
    public text!: string;

    @Prop({ default: '' })
    public action!: string;

    @Prop({ default: false })
    public cartBadge!: boolean;

    @Prop({ default: false })
    public disabled!: boolean;

    @Prop({ default: true })
    public hideable!: boolean;

    public get showBadge(): boolean {
        return this.cartBadge && this.state.placed.length > 0;
    }

    public get badgeCount(): number {
        return this.state.placed.length;
    }

    public onClick(): void {
        if (!this.disabled) {
            this.$emit('click');
        }
    }
}
