





















import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GlobalState from '@/store/GlobalState';
import Cart from '@/cart/Cart';
import CartItem from '@/components/CartItem.vue';

@Component({
    components: {
        CartItem,
    },
})
export default class CartView extends Vue {
    private state = getModule(GlobalState, this.$store);

    public cart = new Cart();

    @Watch('state.placed')
    public updateCart(): void {
        this.cart.updateModels(this.state.placed);
    }

    public onClose(): void {
        this.state.setShowCart(false);
    }

    public onCheckout(): void {
        this.state.setShowCart(false);
        this.state.setShowDemoHint(true);
    }
}
