








import { Component, Vue, Prop } from 'vue-property-decorator';
import { Model } from '@bitforgehq/yago-api-client';

@Component
export default class ModelCard extends Vue {
    @Prop()
    public model!: Model;

    public get displayPrice(): string {
        if (!this.model.price || !this.model.priceCurrency) return 'XX.- CHF';
        return `${this.model.price} ${this.model.priceCurrency}`;
    }
}
